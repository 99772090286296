:global(#global-selector) {
	@keyframes fade-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.wrapper {
		width: 100%;
		min-height: 100vh;

		animation-name: fade-in;
		animation-duration: 0.3s;
		animation-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);

		input:-webkit-autofill,
		input:-webkit-autofill:focus {
			transition:
				background-color 0s 600000s,
				color 0s 600000s !important;
		}
	}

	.bg {
		position: fixed;
		z-index: 0;
		right: 0;
		bottom: 0;

		width: 90vw;
		min-width: 905px;
		height: auto;
		min-height: 500px;

		// max-height: 86.9vh;

		color: var(--ant-color-primary);

		// path {
		// 	transform: scale(1);
		// 	animation: path 0.3s ease-in-out;
		// 	animation-fill-mode: forwards;

		// 	&:nth-child(4) {
		// 		animation-delay: 0.1s;
		// 	}

		// 	&:nth-child(3) {
		// 		animation-delay: 0.3s;
		// 	}

		// 	&:nth-child(2) {
		// 		animation-delay: 0.5s;
		// 	}

		// 	&:nth-child(1) {
		// 		animation-delay: 0.7s;
		// 	}
		// }
	}

	.login {
		display: flex;
		align-items: center;
		min-height: 100vh;
		padding-left: 10%;

		@media (max-width: 1024px) {
			justify-content: center;
			padding-left: 0;
		}
	}

	.card {
		min-width: 500px;
		background: #242424;

		.body {
			padding: 48px;
		}

		@media screen and (max-width: 532px) {
			min-width: calc(100vw - 32px);
		}
	}

	.header {
		margin-bottom: var(--ant-margin-md);
		color: var(--ant-color-text-base);
		text-align: center;
	}

	.title {
		font-size: 64px;
		font-weight: 600;
		font-style: normal;
		line-height: 64px;
		color: var(--ant-color-text-base);
		text-align: center;
		text-decoration-line: underline;
	}

	.subtitle {
		font-size: 20px;
	}

	.desc {
		max-width: 400px;
		margin-bottom: var(--ant-margin-md);

		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		line-height: 20px;
		color: var(--ant-color-text-description);
		text-align: center;
	}

	.name {
		font-size: 14px;
		font-weight: 900;
		font-style: normal;
		line-height: 20px;
		color: var(--ant-color-text-base);
		text-align: center;
	}

	.company {
		margin-bottom: var(--ant-margin-md);
		text-align: center;
	}
}

@keyframes path {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.15);
	}
}
