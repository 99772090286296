.wrapper {
	--header-height: 40px;
	--title-height: 54px;
	--border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
	
	position: fixed;
	z-index: 3001;

	width: 500px;

	line-height: var(--ant-line-height);
	color: var(--ant-color-text-secondary);

	background: var(--ant-layout-body-bg);
}

.full_chat {
	top: 0;
	width: 100%;
}

.row {
	flex-wrap: nowrap;
	height: calc(100vh - var(--header-height));
	border-top: var(--border);
}

.collapsedRow {
	height: calc(100vh - var(--ant-layout-header-height));
	border-top: none;
}

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

:global(#global-selector) {
	.action_button {
		width: var(--header-height);
		height: var(--header-height);
	}
	
	.category_button {
		justify-content: flex-start;
		color: var(--ant-color-text-secondary);

		&:hover {
			background: var(--ant-color-bg-elevated);
		}
	}

	.sidebar_button {
		width: var(--title-height);
		height: var(--title-height);
		border-right: none;
	}

	.search_button {
		padding-inline: 5px;
	}

	.filter_button {
		height: var(--ant-control-height-sm);
		padding-inline: 5px;
	}
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: var(--title-height);
	margin-bottom: var(--ant-margin);
	padding: var(--ant-padding) var(--ant-padding-xs) var(--ant-padding) var(--ant-padding);

	font-size: var(--ant-font-size-lg);
	color: var(--ant-color-text);

	border-bottom: var(--border);
}

.empty_title {
	justify-content: flex-end;
}

.title_text {
	overflow: hidden;
	margin-right: var(--ant-margin-xs);
	text-overflow: ellipsis;
	white-space: nowrap;
}

.main {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
}

.selected {
	background: var(--ant-color-bg-elevated);
}

.userName {
	overflow: hidden;
	padding: var(--ant-padding);
	font-size: var(--ant-font-size-sm);
    text-overflow: ellipsis;
}

.first_column {
	width: 208px;
	min-width: 208px;
}

.second_column {
	width: 440px;
	min-width: 440px;
	border-inline: var(--border);
}

.third_column {
	flex-grow: 1;
	min-width: 0;
}

.list {
	overflow: auto;
	height: calc(100% - var(--ant-margin) - var(--title-height));
}

.icon {
	font-size: var(--ant-font-size-lg);
}

.action_icon {
	font-size: var(--ant-font-size-xl);
}

.filter_icon {
	font-size: var(--ant-font-size-sm);
}

.date {
	font-size: var(--ant-font-size);
	color: var(--ant-color-text-disabled);
	white-space: nowrap;
}

.empty_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100%;
}

.chat_content {
	display: flex;
    flex-direction: column;
	justify-content: flex-end;

	height: 100%;
	padding: var(--ant-padding);
}

.sidebar {
	background-color: var(--ant-color-bg-container);
	border-left: var(--border);
}

.sidebar_open {
	width: 500px;
}

.ai_icon {
	margin: var(--ant-font-size);
}

@media screen and (max-width: 1200px) {
	.sidebar_open {
		position: absolute;
		z-index: 3002;
		right: 0;
	}
}

@media screen and (max-width: 1600px) {	
	.first_column {
		width: 168px;
		min-width: 168px;
	}

	.second_column {
		width: 272px;
		min-width: 272px;
	}
}

@media screen and (max-width: 1440px) {	
	.first_column {
	 	width: 128px;
		min-width: 128px;
	}

	.second_column {
		width: 208px;
		min-width: 208px;
	}
}
