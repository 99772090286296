.wrapper {
	position: absolute;
	z-index: 1000000;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	&::after {
		content: '';

		position: absolute;

		width: 100%;
		height: 100%;

		background: linear-gradient(
			180deg,
			rgba(44, 44, 44, 0.5) 0%,
			var(--ant-color-bg-elevated) 100%
		);
	}
}
