.card {
	pointer-events: all;

	position: relative;
	z-index: 10000;

	width: 440px;
	padding: var(--ant-padding-md);

	line-height: var(--ant-line-height);
	text-align: center;

	background: var(--ant-color-bg-elevated);
	border: 1px solid var(--ant-color-border-secondary);
	box-shadow: var(--ant-box-shadow);
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: var(--ant-padding-md);
}

.title {
	margin-bottom: 0.5em;
	font-size: var(--ant-font-size-heading-3);
}

.desc {
	color: var(--ant-color-text-description);
}

.icon {
	font-size: 48px;
}

.footer {
	display: flex;
	justify-content: center;
}

.timer {
	font-size: 48px;
	line-height: 1;
}

.divider {
	clear: both;
	display: block;

	min-width: 100%;
	margin: var(--ant-margin-sm) 0;

	border-block-start: var(--ant-line-width) solid var(--ant-color-border-secondary);
}

:global(#global-selector) {
	.modal__content {
		padding: 0;
	}
}

.close {
	position: absolute;
	top: calc(var(--ant-margin-md) + 8px);
	right: calc(var(--ant-margin-md) - 8px);
	transform: translate(0, -50%);
}
