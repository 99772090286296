.close {
	cursor: pointer;

	width: var(--ant-control-height);
	height: var(--ant-control-height);
	padding: 0;

	font-weight: var(--ant-font-weight-strong);
	line-height: 1;
	color: var(--ant-color-icon);
	text-decoration: none;

	background: transparent;
	border: 0;
	border-radius: var(--ant-border-radius-sm);
	outline: 0;

	transition:
		color var(--ant-motion-duration-mid),
		background-color var(--ant-motion-duration-mid);

	&:hover {
		color: var(--ant-color-icon-hover);
		text-decoration: none;
		background-color: var(--ant-color-bg-text-hover);
	}

	&:focus-visible {
		outline: var(--ant-line-width-focus) solid var(--ant-color-primary-border);
		outline-offset: 1px;
		transition:
			outline-offset 0s,
			outline 0s;
	}

	&:active {
		background-color: var(--ant-color-bg-text-active);
	}
}
