:global(#global-selector) {
	.form {
		width: 100%;
		margin-bottom: var(--ant-margin-md);
	}

	:global {
		.ant-form-item {
			--ant-form-item-margin-bottom: 12px;
		}
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: var(--ant-padding-xl) var(--ant-padding-md);
}

.header {
	// margin-bottom: 0.5em;
	font-size: var(--ant-font-size-heading-3);
}

.divider {
	clear: both;
	display: block;

	min-width: 100%;
	margin: var(--ant-margin-sm) 0;

	border-block-start: var(--ant-line-width) solid var(--ant-color-border-secondary);
}
