.container {
	cursor: pointer;

    width: fit-content;
	padding: 0 var(--ant-padding-xs);

	line-height: var(--ant-line-height);

	background-color: var(--ant-color-bg-container);
	border-radius: var(--ant-border-radius-xs);
}

.gradient {
    width: fit-content;
	margin-bottom: var(--ant-margin-xs);
	padding: 1px;

	background: var(--gradient);
	background-clip: padding-box;
	border-radius: var(--ant-border-radius-xs);
}

.recommendation {
	color: transparent;
	background: var(--gradient);
	background-clip: text;
}
