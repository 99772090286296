:global(#global-selector) {
    .tag {
		align-self: center;

		margin: 0 0 var(--ant-margin-xs);

		color: var(--ant-color-text);

		background-color: var(--ant-color-bg-spotlight);
		border-radius: var(--ant-border-radius-xs);
	}
}
