:global(#global-selector) {
	.navbar {
		flex-grow: 1;
		background-color: var(--ant-layout-body-bg);

		:global(.ant-tabs-nav) {
			max-width: calc(100vw - 516px);
			height: 100%;

			&::before {
				display: none;
			}

			@media (max-width: 1024px) {
				max-width: calc(100vw - 396px);
			}
		}

		:global(.ant-tabs-nav-list) {
			height: 100%;
		}

		:global(.ant-tabs-tab) {
			overflow: hidden;

			margin: 0 -1px 0 0;

			background-color: var(--ant-layout-body-bg);
			border: none;
			border-inline: var(--ant-line-width) var(--ant-line-type)
				var(--ant-color-border-secondary);
			border-radius: 0;
		}

		:global(.ant-tabs-nav-wrap) {
			display: block;
		}

		:global(.ant-tabs-tab-btn) {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		:global(.ant-tabs-nav-add) {
			border: none;
		}

		:global(.ant-tabs-nav-more) {
			display: none;
		}

		:global(.ant-tabs-tab.ant-tabs-tab-active) {
			color: var(--ant-tabs-item-color);
			background-color: var(--ant-color-bg-elevated);
		}

		:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
			color: var(--ant-tabs-item-color);
		}
	}

	.button {
		cursor: pointer;

		display: inline-flex;
		align-items: center;
		justify-content: center;

		width: 52px;
		height: 100%;

		background-color: var(--ant-layout-body-bg);
		border: none;
		border-radius: 6px;
		box-shadow: none;

		transition: all 0.2s;

		&:hover {
			color: var(--ant-tabs-item-hover-color);
		}
	}
}
