:global(#global-selector) {
	.logo {
		overflow: hidden;
		display: flex;
		flex-shrink: 0;
		align-items: center;

		margin: 0;
		padding: 5px 20px;

		font-size: 32px;
		font-weight: bold;
		line-height: 1;
		color: var(--ant-color-text);
		text-align: center;
		white-space: nowrap;

		background-color: var(--ant-color-primary);
	}

	.header {
		position: sticky;
		z-index: 101;
		top: 0;

		display: flex;
		justify-items: stretch;

		width: 100%;
		height: var(--ant-layout-header-height);
		padding: 0;

		background: var(--ant-color-bg-layout);
		border-bottom: var(--ant-line-width) var(--ant-line-type) var(--ant-color-split);

		:global(.ant-badge-count) {
			color: var(--ant-color-text);
		}
	}

	.content {
		display: flex;
		flex-direction: column;

		// color: white;
	}

	.footer {
		text-align: center;
	}
}
