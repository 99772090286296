.container {
	cursor: pointer;

	display: flex;
	gap: var(--ant-margin);
	align-items: center;

	padding: var(--ant-padding-sm) var(--ant-padding);

	line-height: var(--ant-line-height);

	background-color: var(--ant-color-bg-container);
	border-radius: 10px;
}

.gradient {
	margin-bottom: var(--ant-margin-sm);
	padding: 1px;

	background: var(--gradient);
	background-clip: padding-box;
	border-radius: 10px;
}

.title {
	font-size: var(--ant-font-size);
	color: transparent;
	background: var(--gradient);
	background-clip: text;
}

.desc {
	font-size: var(--ant-font-size-sm);
	color: var(--ant-color-text-disabled);
}
