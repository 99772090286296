.wrapper {
	div.title {
		color: var(--ant-card-title-color);
		text-align: var(--ant-card-title-align);
	}

	div.header {
		font-weight: 400;
		border-bottom: var(--ant-card-header-border-bottom);
	}

	div.body {
		padding: var(--ant-card-padding-top) var(--ant-card-padding-right)
			var(--ant-card-padding-bottom) var(--ant-card-padding-left);
	}
}

.scrolling-body {
	.body {
		overflow: auto;
		height: calc(100% - var(--ant-card-header-height));
	}
}
