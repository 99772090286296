:global(#global-selector) {
	.tabs {
		height: 100%;
	}

	.scrolling {
		:global {
			.ant-tabs-content {
				overflow: auto;
				height: 100%;
			}
	
			.ant-tabs-tabpane {
				height: 100%;
			}

		}
	}

	.resetMargin {
		:global(.ant-tabs-nav) {
			margin-bottom: 0;
		}
	}
}
