.content {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: var(--ant-padding)
}

.empty_content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: center;
	justify-content: center;

	color: var(--ant-color-text-secondary);
}

.recommendations {
	margin-right: auto;
	margin-bottom: var(--ant-margin-xs);
}

.title {
	margin-bottom: var(--ant-margin-xs);

	font-weight: 500;
	color: transparent;

	background: var(--gradient);
	background-clip: text;
}

.text {
	margin-bottom: var(--ant-margin-xl);
}

