.input_wrapper {
	position: relative;

	.send {
		right: var(--ant-padding);
	}

	.button {
		position: absolute;
		bottom: var(--ant-padding-sm);

		padding: 0;

		color: var(--ant-color-text-disabled);

		border: none;
	}

	.mention {
		left: var(--ant-padding);
	}

	.clip {
		left: var(--ant-padding);
	}

	.clip_chat {
		left: calc(var(--ant-padding) + var(--ant-font-size-xl) + var(--ant-padding));
	}

	.input {
		// padding-right: var(--ant-margin-xxl);
		font-size: var(--ant-font-size-lg);

		&:focus-within {
			border-color: var(--ant-color-text);

			~ .send {
				color: var(--ant-color-icon-hover);
			}
		}
	}

	.ai_input {
		&:focus-within {
			border-color: transparent;
		}
	}

	.input textarea {
		padding: var(--ant-padding);
	}
}

.gradient {
	position: relative;

	&::before {
		content: '';

		position: absolute;
		top: -1px;
		right: -1px;
		bottom: -1px;
		left: -1px;

		opacity: 0;
		background: var(--gradient);
		border-radius: var(--ant-border-radius);

		transition: all 0.2s;
	}
}

.gradient_active::before {
	opacity: 1;
}

.action_icon {
	font-size: var(--ant-font-size-xl);
}
