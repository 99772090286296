.time {
	color: var(--ant-color-text-disabled);
}

.wrapper {
	margin-bottom: var(--ant-margin-xs);
	padding: var(--ant-padding);
	line-height: var(--ant-line-height);
	color: var(--ant-color-text);

	.button {
		display: none;
		padding-inline: 5px;
	}

	&:hover {
		background: var(--ant-color-bg-elevated);

		.time {
			display: none;
		}

		.button {
			display: block;
		}
	}
}

.name {
	margin-right: var(--ant-margin-xs);
	font-weight: 600;
}

:global(#global-selector) {
	.icon {
		font-size: var(--ant-font-size-lg);
	}

	.dropdown {
		z-index: 3002;
	}
}

.action_icon {
	font-size: var(--ant-font-size-xl);
}

.info {
	margin-left: var(--ant-margin-xs);
	font-size: var(--ant-font-size-sm);
	font-weight: 400;
	color: var(--ant-color-text-disabled);
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: var(--ant-control-height);
	margin-bottom: var(--ant-margin-xxs);
}
