.container {
	width: 100%;
}

:global(#global-selector) {
	.button {
		padding-inline: var(--ant-padding-xs);
	}

	.input {
		padding: 0;
	}

	.prefix {
		font-size: var(--ant-font-size-xl);
		color: var(--ant-color-text-secondary);
	}
}

