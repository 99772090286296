.container {
	position: fixed;
	z-index: 3001;
	top: var(--ant-layout-header-height);
	right: 0;

	width: 500px;
	height: calc(100vh - var(--ant-layout-header-height));

	background-color: var(--ant-color-bg-container);
	border-left: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
	box-shadow: var(--ant-box-shadow);
}

.container__collapsed {
	width: unset;
}
