.actions {
	display: none;
}

.wrapper {
	cursor: pointer;
	padding: var(--ant-padding-xs) var(--ant-padding);
	line-height: var(--ant-line-height);
	transition: 0.2s var(--ant-motion-ease-in-out);

	&:hover {
		background: var(--ant-color-bg-elevated);

		// TODO: DEMO
		// .actions {
		// 	display: flex;
		// 	gap: 8px;
		// }

		// .date {
		// 	display: none;
		// }
	}
}

.selected {
	background: var(--ant-color-bg-elevated);
}

:global(#global-selector) {
	.button {
		height: var(--ant-control-height-sm);
		padding: 0;
	}
}

.action_icon {
	font-size: var(--ant-font-size-xl);
}

.name {
	overflow: hidden;

	margin: 0 var(--ant-margin-xs) var(--ant-margin-xxs) 0;

	font-weight: 500;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.message {
	overflow: hidden;

	margin-right: var(--ant-margin);

	color: var(--ant-color-text-description);
	text-overflow: ellipsis;
	white-space: nowrap;
}

.disabled {
	color: var(--ant-color-text-disabled);
}
