.sidebar {
	--title-height: 54px;
	--gradient: linear-gradient(150deg, #7F66FA, #3777BA, #6ACB96);
	
	height: 100%;

	:global {
		.ant-tabs-nav {
			height: var(--title-height);
			padding: 0 var(--ant-padding) 0 var(--ant-padding-xl);
		}

		.ant-tabs-content {
			height: 100%;
		}

		.ant-tabs-tabpane {
			height: 100%;
		}
	}

	.tabs {
		height: 100%;
	}

	.button {
		position: absolute;
		top: var(--ant-padding-sm);
		right: var(--ant-padding);

		padding: 0;

		border: none;
		box-shadow: none;
	}
}

.icon {
	margin-right: var(--ant-margin-xs);
}

.collapse_icon {
	font-size: var(--ant-font-size-xl);
}
