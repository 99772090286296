:global(#global-selector) {
	.wrapper {
		display: flex;
		align-items: center;

		// justify-content: center;
		background-color: var(--ant-color-bg-spotlight);

		.button {
			cursor: pointer;

			display: inline-flex;
			align-items: center;
			justify-content: center;

			width: 32px;
			height: 100%;

			background: transparent;
			border: none;
			border-color: #d9d9d9;
			border-bottom: var(--ant-line-width) var(--ant-line-type) transparent;
			border-radius: 2px;

			transition: all 0.2s;

			&:hover {
				background: rgba(0, 0, 0, 0.4);
			}
		}

		.ai_button {
			width: 52px;
		}

		.selected {
			color: var(--ant-color-link);
			border-bottom: var(--ant-line-width) var(--ant-line-type) var(--ant-color-link);
		}

		.user {
			width: fit-content;
			padding: 0 16px;
			white-space: nowrap;
		}

		.user_icon {
			display: none;
		}

		@media (max-width: 1024px) {
			.name {
				display: none;
			}

			.user {
				padding: 1px 6px;
			}

			.user_icon {
				display: block;
			}
		}
	}

	.dark {
		background-color: var(--ant-layout-body-bg);
	}
}
